import Alpine from "alpinejs";

Alpine.data('subscriptionSearch', (postUrl = '')=> ({
    searchData: {
        email: "",
        last4: "",
    },
    cancelData: {
        subscription_id: "",
    },
    postUrl,
    formLoading: false,
    searchText: "Search",
    cancelText: "Cancel Subscription",
    successMessage: "",
    errorMessage: "",
    searchSubscription() {
        this.errorMessage = "";
        this.searchText = "Submitting...";
        fetch(this.postUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify(this.searchData),
        })
            .then(response => response.json())
            .then(data => {
                this.searchText = "Search"
                if (data.status === 'success') {
                    this.cancelData.subscription_id = data.id;
                } else {
                    this.errorMessage = data.errors;
                }
            }).catch(() => {
            this.errorMessage = "Something went wrong.";
        });
    },
    cancelSubscription() {
        this.errorMessage = "";
        this.cancelText = "Submitting...";
        fetch(this.postUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify(this.cancelData),
        })
            .then(response => response.json())
            .then(data => {
                this.cancelText = "Cancel Subscription"
                if (data.status === 'success') {
                    this.successMessage = data.message;
                } else {
                    this.errorMessage = data.errors;
                }
            }).catch(() => {
            this.errorMessage = "Something went wrong.";
        });
    },
}))